<app-footer>
  <div class="c-footer-nav">
    <a href="#page-top" class="c-footer-go-to-top is-hidden" data-ref="page-top-button">
      <img src="/img/go-to-page-top.svg" alt="ページトップへスクロールする">
    </a>
    <div class="o-container">
      <div class="o-stack-layout">
        <div>
          <ul class="c-footer-nav__list">
            <li>
              <app-icon-link data-link="/" data-text="ホーム" data-image="/img/icon-tablet.png"></app-icon-link>
            </li>
            <li>
              <app-icon-link data-link="/items/movie.html" data-image="/img/icon-tablet.png" data-text="教材をさがす"></app-icon-link>
            </li>
            <li>
              <app-icon-link data-link="/results" data-image="/img/icon-tablet.png" data-text="実践例をみる"></app-icon-link>
            </li>
            <li>
              <app-icon-link data-link="/rental" data-image="/img/icon-tablet.png" data-text="教材貸出"></app-icon-link>
            </li>
            <li>
              <app-icon-link data-link="/training" data-image="/img/icon-tablet.png" data-text="出前研修"></app-icon-link>
            </li>
            <li>
              <app-icon-link data-link="https://www.rad-ar.or.jp/inquiry/" data-image="/img/icon-tablet.png"
                data-text="お問い合わせ" data-external="{ true }"></app-icon-link>
            </li>
            <li>
              <app-icon-link data-link="/first" data-image="/img/icon-tablet.png" data-text="はじめて利用する方へ"></app-icon-link>
            </li>
            <li>
              <app-icon-link data-link="https://rad-ar.or.jp/blog/category/education/" data-image="/img/icon-tablet.png"
                data-text="新着情報" data-external="{ true }"></app-icon-link>
            </li>
            <li>
              <app-icon-link data-link="/about" data-image="/img/icon-tablet.png" data-text="このサイトについて"></app-icon-link>
            </li>
            <li>
              <app-icon-link data-link="/sitemap" data-image="/img/icon-tablet.png" data-text="サイトマップ"></app-icon-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="c-footer">
    <div class="o-container">
      <div class="o-flow-layout c-footer__layout">
        <div>
          <div class="c-footer__logo">
            <a href="https://www.rad-ar.or.jp/" target="_blank" rel="noopener">
              <img src="/img/icon-company-logo.svg" alt="RAD-AR くすりの適正使用協議会">
            </a>
          </div>
        </div>
        <div class="o-width-fill o-fit c-footer__layout-block-2">
          <p>協賛 公益社団法人 日本薬剤師会</p>
          <p class="c-footer__copyright">Copyright 2025 くすりの適正使用協議会 All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>

  <script>
    import ScrollMagic from 'scrollmagic'

    export default function AppFooter() {
      return {
        onMounted() {
          var controller = new ScrollMagic.Controller()

          new ScrollMagic.Scene()
            .triggerElement('#page-top')
            .triggerHook('onLeave')
            .addTo(controller)
            .offset(100)
            .on('enter', function (e) {
              document.querySelector('[data-ref="page-top-button"]').classList.remove('is-hidden')
            })
            .on('leave', function (e) {
              document.querySelector('[data-ref="page-top-button"]').classList.add('is-hidden')
            });
        }
      }
    }
  </script>
</app-footer>
