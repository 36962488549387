<app-items-list>
  <div class="c-page-header c-page-header--items">
    <h1 class="c-page-header__title">
      <span class="c-page-header__icon">
        <img src="/img/global-nav-icon-items-w.svg" alt="教材をさがす">
      </span>教材をさがす
    </h1>
    <p class="c-page-header__description">
      くすり教育にご活用いただける資材<br class="u-only-sp">（動画、パワーポイント、PDFファイル等）を掲載しています。
    </p>
    <ul class="c-items-list-categories">
      <li class="c-items-list-categories__item {isCurrentCategory('movie')}">
        <a href="/items/movie.html">動画</a>
      </li>
      <li class="c-items-list-categories__item {isCurrentCategory('document')}">
        <a href="/items/document.html">パワーポイント・リーフレット</a>
      </li>
      <li class="c-items-list-categories__item {isCurrentCategory('illust')}">
        <a href="/items/illust.html">イラスト集</a>
      </li>
      <li class="c-items-list-categories__item {isCurrentCategory('rental')}">
        <a href="/items/rental.html">教材貸出</a>
      </li>
      <li class="c-items-list-categories__item {isCurrentCategory('others')}">
        <a href="/items/others.html">その他</a>
      </li>
    </ul>
  </div>
  <section class="c-result">
    <div class="o-container">
      <p class="c-item-search-count">
        <!--  { state.filtered ? state.filtered.length : 0 } / { state.items ? state.items.length : 0 }  -->
        <span>表示数：</span>
        { state.filtered ? state.filtered.length : 0 }
      </p>
      <div class="c-result-list">
        <div id="{ item.slug }" each="{ item in state.filtered }" class="c-items-item">
          <div class="c-item-target-list">
            <div class="c-item-target" each="{ element in createCombinedList(item.targets, item.formats) }">
              { element }
            </div>
          </div>
          <div class="c-items-item__title c-heading-6">
            <raw-html content="{ item.name }"></raw-html>
          </div>
          <div class="c-items-item-grid">
            <!--  <app-carousel data-photos="{ item.photo }"></app-carousel>  -->
            <div class="c-carousel-wrapper">
              <div class="js-slick-carousel { item.photo.length === 1 ? 'single-photo' : '' }">
                <div each="{ photo in item.photo }" class="c-items-item__photo">
                  <img src="{ photo }"></img>
                </div>
              </div>
            </div>
            <div class="c-items-item__summary o-fit">
              <raw-html content="{ item.description }"></raw-html>
              <div class="c-items-item__slug">ID: { item.slug }</div>
            </div>
            <div class="c-items-item__buttons">
              <!-- aタグ (メール不要) -->
              <template each="{ button in item.links }">
                <!-- メール不要の場合 -->
                <a if="{ !button.required_mail }"
                  class="c-button-3 c-items-item__button c-items-item__button--{ button.type }"
                  href="{ button.url }"
                  target="_blank"
                  rel="noopener noreferrer">
                  <div class="c-button-3__label">
                    <raw-html content="{ button.name }"></raw-html>
                  </div>
                  <app-icon class="c-button-3__icon" data-icon="{ button.type }"></app-icon>
                </a>

                <!-- メールが必要な��合 -->
                <button if="{ button.required_mail }"
                        class="c-button-3 c-items-item__button c-items-item__button--{ button.type }"
                        onclick="{ clickButton.bind(this, button, item) }">
                  <div class="c-button-3__label">
                    <raw-html content="{ button.name }"></raw-html>
                  </div>
                  <app-icon class="c-button-3__icon" data-icon="{ button.type }"></app-icon>
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!--  <app-items-search-modal data-data="{ state.filtered }"></app-items-search-modal>  -->
      <app-items-search-modal data-data="{ state.modalData }"></app-items-search-modal>

    </div>
  </section>

  <script>
    import 'whatwg-fetch'
    import $ from 'jquery'
    window.$ = window.jQuery = $
    import 'slick-carousel'

    export default {
      state: {
        items: null,
        filtered: null,
        currentCategory: null,
        categoryMap: {
          'movie': '動画',
          'document': 'パワーポイント・リーフレット', // 修正：documentとrentalを入れ替え
          'illust': 'イラスト集',
          'rental': '教材貸出',
          'others': 'その他'
        }
      },
      onBeforeMount() {
        // URLからカテゴリーを取得
        const pathParts = window.location.pathname.split('/');
        const htmlFile = pathParts[pathParts.length - 1];
        const category = htmlFile.replace('.html', '');
        this.state.currentCategory = category;
      },

      onMounted() {
        // データ読み込み前にハッシュを保存
        const initialHash = window.location.hash;

        fetch('../data/data.json')
          .then(response => response.json())
          .then(data => {
            this.initData(data);
            this.update();
            this.initializeSliders();

            // データ読み込み後にスクロール
            if (initialHash) {
              this.scrollToHash(initialHash);
            }
          });

        // ブラウザの戻る・進むボタンでのハッシュ変更を監視
        window.addEventListener('hashchange', () => {
          this.scrollToHash(window.location.hash);
        });
      },
      scrollToHash(hash) {
        if (!hash) return;

        const id = hash.replace('#', '');
        const element = document.getElementById(id);

        if (element) {
          setTimeout(() => {
            // ヘッダーの高さを取得
            const header = document.querySelector('.c-page-header');
            const headerHeight = header ? header.offsetHeight : 0;

            // スクロール位置を計算
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset;

            // スクロール実行
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }, 300);
        }
      },
      // スライダーの初期化
      initializeSliders() {
        $('.js-slick-carousel').each((index, element) => {
          const $carousel = $(element)

          // First, destroy any existing slick initialization
          if ($carousel.hasClass('slick-initialized')) {
            $carousel.slick('unslick')
          }

          // Only initialize slick for carousels with multiple photos
          if (!$carousel.hasClass('single-photo')) {
            this.initSlick($carousel)
          }
        })
      },
      initSlick($carousel) {
        // スライダーの初期化
        $carousel.slick({
          dots: true,
          arrows: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          prevArrow: '<a class="slick-prev" href="#"><img src="../../img/arw_left.png"></a>',
          nextArrow: '<a class="slick-next" href="#"><img src="../../img/arw_right.png"></a>'
        })

        // 位置とドットのリセット
        $carousel.slick('slickGoTo', 0)
        $carousel.slick('setPosition')
      },
      reinitializeSliders() {
        setTimeout(() => {
          $('.js-slick-carousel').each((index, element) => {
            const $carousel = $(element);

            // 既存のslickを破棄
            if ($carousel.hasClass('slick-initialized')) {
              $carousel.slick('unslick');
            }

            // 複数画像の場合のみ再初期化
            if (!$carousel.hasClass('single-photo')) {
              this.initSlick($carousel);
            }
          });
        }, 0);
      },
      // カテゴリーの変更
      initData(data) {
        this.state.items = data.items;
        if (this.state.currentCategory) {
          this.filterByCategory(this.state.currentCategory);
        } else {
          // カテゴリーが指定されていない場合は全アイテムを表示
          this.state.filtered = [...this.state.items];
          //console.log('Showing all items:', this.state.filtered.length);
        }
      },

      filterByCategory(category) {
        if (!category || !this.state.categoryMap[category]) return;

        const categoryName = this.state.categoryMap[category];
        this.state.filtered = this.state.items.filter(item => {
          const itemCategories = Array.isArray(item.categories) ? item.categories : [item.categories];
          return itemCategories.includes(categoryName);
        });

        this.update();
        this.reinitializeSliders();

        // フィルター後にハッシュがある場合は該当位置にスクロール
        if (window.location.hash) {
          this.scrollToHash(window.location.hash);
        }
      },

      isCurrentCategory(category) {
        if (!this.state.currentCategory) return '';
        const isCurrent = this.state.currentCategory === category;
        //console.log(`Checking current category ${category}:`, isCurrent);
        return isCurrent ? 'is-current' : '';
      },

      // データの更新
      createCombinedList(targets, formats) {
        if (this.checkTargetsAll(targets)) {
          return ['全対象者', ...formats];
        }
        return [...targets, ...formats];
      },
      dataCallModal(data) {
        // モーダルに表示するデータを設定
        this.state.modalData = data;
        this.update();
        location.href = "#modal"; // モーダルを表示
      },
      clickButton(button, item) {
        if (button.required_mail) {
          this.dataCallModal({ button, item }); // 必要なデータをモーダルに渡す
        } else {
          const sendData = {
            a1: '',
            a2: button.name,
            a3: button.url,
            a4: `${item.slug}-${button.slug}`
          };
          const fileUrl = button.url;

          $.post(location.origin + '/js/kyozai.cgi', sendData, function () {
            location.href = fileUrl;
            location.hash = '#close-modal';
          }).fail(() => {
            alert('通信中に問題が発生しました。\n恐れ入りますがしばらく立ってから再度お試しください。');
          });
        }
      },
      checkTargetsAll(arr) {
        const targets = ['target1', 'target2', 'target3']; // 対象者のラベルリスト
        return targets.every(target => arr.includes(target));
      }
    }


  </script>
</app-items-list>
