<app-check-box-text>
  <label class="c-check-box-text{ state.isChecked ? ' c-check-box-text--checked' : null }">
  <input type="checkbox" name="" id="" class="c-check-box-text__input" checked="{ state.isChecked }" onchange="{ toggleCheck }">
    <div class="c-check-box-text__checkbox{
        props.dataTheme ? ` c-check-box-text__checkbox--${ props.dataTheme }` : null
      }">
    </div>
    <div class="c-check-box-text__text{
        props.dataTheme ? ` c-check-box-text__text--${ props.dataTheme }` : null
      }{
        state.isChecked ? ' c-check-box-text__text--checked' : null
      }">{ props.dataText }</div>
  </label>

  <script>
    export default {
      state: {
        isChecked: false,
      },
      onBeforeMount() {
        this.changeState()
      },
      onBeforeUpdate(){
        this.changeState()
      },
      changeState(){
        this.state.isChecked = this.props.dataChecked
      },
      toggleCheck(){
        this.state.isChecked = !this.state.isChecked
        this.props.dataToggle({
          type: this.props.dataTheme,
          label: this.props.dataText,
          checked: this.state.isChecked
        })
      },
    }
  </script>
</app-check-box-text>