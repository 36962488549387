<app-banners>
  <div class="o-container" if="{ state.bannersData }">
    <div class="o-grid-3col">
      <div each="{ banner in state.bannersData }">
        <a href="{ banner.link }" target="{ getIsExternal(banner.link) ? '_blank' : false}"
          rel="{ getIsExternal(banner.link) ? 'noopener' : false}">
          <img src="{ banner.image }" alt="{ banner.title }">
        </a>
      </div>
    </div>
  </div>


  <script>
    export default function appBanners() {
      return {
        state: {
          bannersData: null
        },
        onMounted() {
          fetch('/data/banners.json')
            .then(response => response.json())
            .then(data => {
              // console.log(data)
              this.state.bannersData = data
              this.update()
            })
        },
      }
    }
  </script>
</app-banners>
