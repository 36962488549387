<app-result-detail>
  <div class="c-page-header">
    <h1 if={ state.case } class="c-page-header__title">
      { state.case.name }
    </h1>
    <h1 if={ !state.case && state.loading } class="c-page-header__title">
      読み込み中...
    </h1>
    <h1 if={ !state.case && !state.loading && state.error } class="c-page-header__title">
      エラーが発生しました
    </h1>
    <p if={ state.case } class="c-page-header__date">
    掲載日：
    { state.case.date }
    </p>
  </div>

  <div if={ state.case } class="c-article">
    <div class="c-article__body">
      <!-- repeatItem -->
      <div each={ item in state.case.repeatItem }
           if={ shouldShowSection(item) }
           class="c-section { getSectionClass(item) }">
        <h2 class="c-heading-3">{ item.name }</h2>

        <!-- 写真付きの場合 -->
        <div if={ hasPhotos(item) } class="c-grid">
          <div class="c-grid__item">
            <div class="js-slick-slider" ref="slider">
              <div each={ photo in item.photo } class="c-slider__item">
                <img src={ photo } alt="" />
              </div>
            </div>
          </div>

          <div if={ item.txt } class="c-grid__item">
            <div class="c-wysiwyg" data-html={ item.txt }></div>
          </div>
        </div>

        <!-- テキストのみの場合 -->
        <!--  <div if={ !hasPhotos(item) && item.txt } class="c-wysiwyg">
          { item.txt }
        </div>  -->
        <div if={ !hasPhotos(item) && item.txt } class="c-wysiwyg" data-html={ item.txt }></div>

      </div>

      <!-- 企画・運営 -->
      <div if={ state.case.planner && state.case.planner.length && hasContent(state.case.planner) } class="c-section">
        <h2 each={ item in state.case.planner } class="c-heading-3">{ item.name }</h2>
        <div each={ item in state.case.planner } class="c-wysiwyg" data-html={ item.txt }></div>
      </div>

      <!-- 使用教材 -->
      <div if={ state.case.materials && state.case.materials.length } class="c-section">
        <h2 class="c-heading-3">使用教材</h2>
        <div class="c-table-wrp">
          <table class="c-table">
            <tbody>
              <tr each={ (material, index) in state.case.materials }>
                <td class="c-table-item_txt">{ index + 1 }</td>
                <td>
                  <a if={ material.url } href={ material.url } target="_blank" rel="noopener">
                    { material.name }
                  </a>
                  <p if={ !material.url } >
                    { material.name }
                  </p>
                </td>
                <td><p data-html={ material.txt }></p></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>



      <!-- 使用機材 -->
      <div if={ state.case.equipment && state.case.equipment.length && hasContent(state.case.equipment) } class="c-section">
        <h2 each={ item in state.case.equipment } class="c-heading-3">{ item.name }</h2>
        <div each={ item in state.case.equipment } class="c-wysiwyg" data-html={ item.txt }></div>
      </div>

      <!-- スケジュール -->
      <div if={ state.case.schedule && state.case.schedule.length && hasScheduleContent(state.case.schedule) } class="c-section">
        <h2 class="c-heading-3">スケジュール</h2>
        <div class="c-grid">
          <div each={ item in state.case.schedule } class="c-schedule-item" if={ item.url || item.photo }>
            <h3 class="c-schedule-ttl">{ item.name }</h3>

            <!-- 画像のみの場合 -->
            <div if={ item.photo && !item.url } class="c-schedule-link">
              <img src={ item.photo } alt="" />
            </div>

            <!-- URLがある場合 -->
            <a if={ item.url } href={ item.url } class="c-schedule-link" target="_blank">
              <img if={ item.photo } src={ item.photo } alt="" />
              <p>
                ダウンロードする
                <span><img src="/img/icon-dl.png" alt="" /></span>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div if={ state.loading } class="c-loading">
    <p>データを読み込んでいます...</p>
  </div>

  <div if={ state.error } class="c-error">
    <p>エラー: { state.error }</p>
  </div>

  <script>
  import $ from 'jquery'
  window.$ = window.jQuery = $
  import 'slick-carousel'

  export default {
    state: {
      case: null,
      loading: true,
      error: null
    },

    // 式のHTMLレンダリング設定
    expressions: {
      item: {
        txt: { rawHtml: true },
        name: { rawHtml: true }
      },
      material: {
        name: { rawHtml: true },
        txt: { rawHtml: true }
      }
    },

    onBeforeMount(props) {
      // data-case属性がある場合は、そのデータを使用
      if (this.root.hasAttribute('data-case')) {
        try {
          const caseData = this.root.getAttribute('data-case')
          this.state.case = JSON.parse(caseData)
          this.state.loading = false
        } catch (e) {
          console.error('Parse error:', e)
          this.state.error = 'データの解析中にエラーが発生しました'
          this.state.loading = false
        }
      }
      // data-slug属性がある場合は、そのslugでデータを取得
      else if (this.root.hasAttribute('data-slug')) {
        const slug = this.root.getAttribute('data-slug')
        this.fetchCaseBySlug(slug)
      }
      // どちらもない場合はエラー
      else {
        this.state.error = 'ケースデータまたはスラグが指定されていません'
        this.state.loading = false
      }
    },

    onMounted() {
      if (this.state.case) {
        this.update()
        setTimeout(() => {
          this.initSliders();
           this.renderHTML();
        }, 100)
      }
    },

    // コンポーネントに関数を追加
    parseHTML(html) {
      if (!html) return '';
      // HTMLエスケープを元に戻す
      return html
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'");
    },
    // レンダリング関数
    renderHTML() {
      const htmlElements = this.$$('[data-html]');
      if (htmlElements && htmlElements.length) {
        htmlElements.forEach(el => {
          const html = el.getAttribute('data-html');
          if (html) {
            el.innerHTML = html;
          }
        });
      }
    },

    onUpdated() {
      this.initSliders();
        this.renderHTML();
    },

    // slugを使ってケースデータを取得する関数
    async fetchCaseBySlug(slug) {
      this.state.loading = true
      this.state.error = null

      try {
        // cases.jsonファイルを取得
        const response = await fetch('/data/cases.json')

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()

        if (!data || !data.items || !Array.isArray(data.items)) {
          throw new Error('ケースデータの形式が正しくありません')
        }

        // 指定されたslugのケースデータを検索
        const caseData = data.items.find(item => item.slug === slug)

        if (!caseData) {
          throw new Error(`指定されたスラグ「${slug}」のケースデータが見つかりません`)
        }

        // ケースデータを設定する前にHTMLをデコード
        if (caseData && caseData.repeatItem) {
          caseData.repeatItem.forEach(item => {
            if (item.txt) {
              item.txt = this.parseHTML(item.txt);
            }
          });
        }

        // 他のデータも同様に処理
        ['planner', 'equipment'].forEach(key => {
          if (caseData[key] && Array.isArray(caseData[key])) {
            caseData[key].forEach(item => {
              if (item.txt) {
                item.txt = this.parseHTML(item.txt);
              }
            });
          }
        });

        // ケースデータを設定
        this.state.case = caseData
        this.state.loading = false
        this.update()

      } catch (error) {
        console.error('Error fetching case data:', error)
        this.state.error = error.message || 'ケースデータの取得中にエラーが発生しました'
        this.state.loading = false
        this.update()
      }
    },

    shouldShowSection(item) {
      return item && (item.txt || (item.photo && item.photo.length > 0) || item.url)
    },

    hasPhotos(item) {
      return item && item.photo && item.photo.length > 0
    },

    hasContent(items) {
      return items && items.some(item => item && item.txt && item.txt.length > 0)
    },

    hasScheduleContent(schedule) {
      return schedule && schedule.some(item => item && (item.url || item.photo))
    },

    getSectionClass(item) {
      return this.hasPhotos(item) ? 'c-section--grid' : 'c-section--single'
    },

    initSliders() {
      const sliders = this.$$('.js-slick-slider:not(.slick-initialized)')
      if (sliders && sliders.length) {
        sliders.forEach(slider => {
          $(slider).slick({
            dots: true,
            arrows: true,
            adaptiveHeight: true,
            prevArrow: '<a class="slick-prev" href="#"><img src="../../img/arw_left.png"></a>',
            nextArrow: '<a class="slick-next" href="#"><img src="../../img/arw_right.png"></a>',
          })
        })
      }
    }
  }
  </script>
</app-result-detail>
