import 'core-js';
import '@webcomponents/template';
import { register, mount, install } from 'riot';
import RawHtml from '../js/components/src/riot/raw-html.js';
import AppHeader from '../js/components/src/riot/app-header.js';
import AppIcon from '../js/components/src/riot/app-icon.js';
import AppIconLink from '../js/components/src/riot/app-icon-link.js';
import AppTopHero from '../js/components/src/riot/app-top-hero.js';
import AppTopPosts from '../js/components/src/riot/app-top-posts.js';
import AppItemsList from '../js/components/src/riot/app-items-list.js';
// import AppItemsSearch from '../js/components/src/riot/app-items-search.js';
// import AppItemsSearchUi from '../js/components/src/riot/app-items-search-ui.js';
import AppCheckBoxText from '../js/components/src/riot/app-check-box-text.js';
import AppItemsSearchResult from '../js/components/src/riot/app-items-search-result.js';
import AppItemsItem from '../js/components/src/riot/app-items-item.js';
import AppCarousel from '../js/components/src/riot/app-carousel.js';
import AppItemsSearchModal from '../js/components/src/riot/app-items-search-modal.js';
import AppFooter from '../js/components/src/riot/app-footer.js';
import AppIconLink2 from '../js/components/src/riot/app-icon-link-2.js';
import AppBanners from '../js/components/src/riot/app-banners.js';
import AppGoogleSearch from '../js/components/src/riot/app-google-search.js';
import AppResultsList from '../js/components/src/riot/app-results-list.js';
import AppResultDetail from '../js/components/src/riot/app-result-detail.js';
import _ from 'lodash';

register('raw-html', RawHtml);
register('app-header', AppHeader);
register('app-icon', AppIcon);
register('app-icon-link', AppIconLink);
register('app-top-hero', AppTopHero);
register('app-top-posts', AppTopPosts);
register('app-items-list', AppItemsList);
// register('app-items-search', AppItemsSearch);
// register('app-items-search-ui', AppItemsSearchUi);
register('app-check-box-text', AppCheckBoxText);
register('app-items-search-result', AppItemsSearchResult);
register('app-items-item', AppItemsItem);
register('app-carousel', AppCarousel);
register('app-items-search-modal', AppItemsSearchModal);
register('app-footer', AppFooter);
register('app-icon-link-2', AppIconLink2);
register('app-banners', AppBanners);
register('app-google-search', AppGoogleSearch);
register('app-results-list', AppResultsList);
register('app-result-detail', AppResultDetail);

let id = 0;
install(function (component) {
  component.uid = id++;
  component._ = _;

  component.getIsExternal = (url) => {
    return url.match(/^https?:/) ? true : false;
  };

  return component;
});

mount('[data-riot]');
