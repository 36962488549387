<app-google-search>
  <form action="https://www.google.co.jp/search" method="get" target="_blank" rel="noopener">
    <div class="c-input-and-button">
      <div class="o-flow-layout o-flow-layout--no-gap o-flow-layout--no-wrap">
        <div>
          <input type="hidden" value="site:https://www.rad-are.com/" name="q">
          <input type="text" class="c-input-and-button__input" name="q" placeholder="サイト内を検索">
        </div>
        <div>
          <button class="c-input-and-button__button" type="submit">
            <app-icon data-icon="search"></app-icon>
          </button>
        </div>
      </div>
    </div>
  </form>
</app-google-search>
