<app-header>
  <div class="c-header">
    <div class="o-container">
      <div class="c-header-inner">
        <div class="c-header__site-logo-wrapper">
          <div class="o-stack-layout c-header__layout">
            <div>
              <p class="c-header__site-description">くすり教育の教材を提供する、くすりの適正使用協議会のサイトです。</p>
            </div>
            <div>
              <a href="/" class="c-header__site-logo">
                <img src="/img/site-logo.svg" alt="くすり教育 担当者のための教材サイト" />
              </a>
            </div>
          </div>
        </div>
        <div class="o-width-fill c-header-nav">
          <div class="o-stack-layout c-header__layout-after">
            <div class="c-header__top-nav-layout-wrapper u-only-sp">
              <p class="c-header__site-description">くすり教育の教材を提供する、<br>くすりの適正使用協議会のサイトです。</p>
              <app-google-search data-link></app-google-search>
            </div>
              <div class="c-global-nav-sub">
                <div class="o-flow-layout o-flow-layout--right c-header__top-nav-layout">
                <div>
                  <app-icon-link data-link="/first" data-text="はじめて利用する方へ" data-image="/img/illust-wakaba-yellow.svg" data-link=""></app-icon-link>
                </div>
                <div>
                  <app-icon-link data-link="https://www.rad-ar.or.jp/" data-text="くすりの適正使用協議会" data-image="/img/illust-capsule.svg" data-link="" data-external="{ true }"></app-icon-link>
                </div>
                <div class="u-only-sp">
                  <app-icon-link-2 data-link="/about" data-icon="medical-tablet" data-label="このサイトについて"></app-icon-link-2>
                </div>
                <div class="u-only-sp">
                  <app-icon-link-2 data-link="/sitemap" data-icon="medical-tablet" data-label="サイトマップ"></app-icon-link-2>
                </div>
                <div class="c-header__social-layout">
                  <div>
                    <a href="https://www.facebook.com/kusuri.tekisei/" class="" target="_blank"><img src="/img/global-nav-icon-facebook.svg" alt="facebook" /></a>
                  </div>
                  <div>
                    <a href="https://www.youtube.com/user/rcj1989" class="" target="_blank"><img src="/img/global-nav-icon-youtube.svg" alt="youtube" /></a>
                  </div>
                  <div>
                    <app-icon-link data-link="https://www.rad-ar.or.jp/mail-magazine" data-text="メルマガ登録" data-image="/img/global-nav-icon-mailmag.svg" data-external="{ true }"></app-icon-link>
                  </div>
                </div>
                <div class="u-only-pc">
                  <app-google-search data-link></app-google-search>
                </div>
              </div>
            </div>
            <nav class="c-global-nav">
              <ul class="c-global-nav__list">
                <li class="c-global-nav__item">
                  <a href="/" class="c-global-nav__link{ props.dataCurrent == 'home' ? ' c-global-nav__link--current' : null }">
                    <!-- <svg class="c-global-nav__icon{ props.dataCurrent == 'home' ? ' c-global-nav__icon--current' : null }" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M29 12L14.813 0L1 12H3.99998V30H26V12H29ZM7.99998 13H13V18H7.99998V13ZM13 21H7.99998V26H13V21ZM22 21H17V26H22V21ZM22 13H17V18H22V13Z" fill="#3B87E3" />
                    </svg> -->
                    <svg class="c-global-nav__icon{ props.dataCurrent == 'home' ? ' c-global-nav__icon--current' : null }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
                      <defs>
                        <clipPath id="a"><rect width="16" height="16" fill="#fff" /></clipPath>
                        <linearGradient id="b" x2="1" y2="1" gradientUnits="objectBoundingBox">
                          <stop offset="0" stop-color="#00dc8d" />
                          <stop offset="1" stop-color="#50a5d3" />
                        </linearGradient>
                      </defs>
                      <g clip-path="url(#a)"><path d="M15.933,6.4,8.367,0,1,6.4H2.6V16H14.333V6.4Zm-11.2.533H7.4V9.6H4.733ZM7.4,11.2H4.733v2.667H7.4Zm4.8,0H9.533v2.667H12.2Zm0-4.267H9.533V9.6H12.2Z" transform="translate(-0.467)" fill-rule="evenodd" fill="url(#b)" /></g>
                    </svg>
                    <span class="c-global-nav__text">ホーム</span>
                  </a>
                </li>
                <li class="c-global-nav__item">
                  <a href="/items/movie.html" class="c-global-nav__link{ props.dataCurrent == 'items' ? ' c-global-nav__link--current' : null }">
                    <span class="c-global-nav__icon{ props.dataCurrent == 'items' ? ' c-global-nav__icon--current' : null }">
                      <img src="/img/global-nav-icon-items.svg" alt="教材をさがす">
                    </span>
                    <span class="c-global-nav__text">教材をさがす</span>
                  </a>
                </li>
                <li class="c-global-nav__item">
                  <a href="/results" class="c-global-nav__link{ props.dataCurrent == 'results' ? ' c-global-nav__link--current' : null }">
                    <span class="c-global-nav__icon{ props.dataCurrent == 'results' ? ' c-global-nav__icon--current' : null }">
                      <img src="/img/global-nav-icon-results.svg" alt="実践例をみる">
                    </span>
                    <span class="c-global-nav__text">実践例をみる</span>
                  </a>
                </li>
                <li class="c-global-nav__item">
                  <a href="/rental" class="c-global-nav__link{ props.dataCurrent == 'rental' ? ' c-global-nav__link--current' : null }">
                    <span class="c-global-nav__icon{ props.dataCurrent == 'rental' ? ' c-global-nav__icon--current' : null }">
                      <img src="/img/global-nav-icon-rental.svg" alt="教材貸出">
                    </span>
                    <span class="c-global-nav__text">教材貸出</span>
                  </a>
                </li>
                <li class="c-global-nav__item">
                  <a href="/training" class="c-global-nav__link{ props.dataCurrent == 'training' ? ' c-global-nav__link--current' : null }">
                    <span class="c-global-nav__icon{ props.dataCurrent == 'training' ? ' c-global-nav__icon--current' : null }">
                      <img src="/img/global-nav-icon-training.svg" alt="出前研修">
                    </span>
                    <span class="c-global-nav__text">出前研修</span>
                  </a>
                </li>
                <li class="c-global-nav__item">
                  <a href="https://www.rad-ar.or.jp/inquiry/" target="_blank" rel="noopener" class="c-global-nav__link{ props.dataCurrent == 'contact' ? ' c-global-nav__link--current' : null }">
                    <span class="c-global-nav__icon{ props.dataCurrent == 'contact' ? ' c-global-nav__icon--current' : null }">
                      <img src="/img/global-nav-icon-contact.svg" alt="お問い合わせ">
                    </span>
                    <span class="c-global-nav__text">お問い合わせ</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <button class="c-header__hamburger-menu js-hamburger-menu">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  </div>

  <script>
  //console.log(props.dataCurrent)
    export default {
      onMounted() {
        const hamburger = this.$('.js-hamburger-menu')
        const nav = this.$('.c-header-nav')

        hamburger.addEventListener('click', () => {
          hamburger.classList.toggle('is-active')
          nav.classList.toggle('is-active')
        })
      }
    }
  </script>


</app-header>
