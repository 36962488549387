<app-results-list>
  <div class="c-section">
    <div class="o-container">
      <div class="o-flow-layout o-flow-layout--loose o-flow-layout--fill">
        <div class="c-results-items">
          <!-- each属性の構文を修正 -->
          <virtual each={ item in displayItems }>
            <!-- 固定ブロック（求人情報） -->
            <div if={ item.isFixed } class="c-results-item c-results-item--fixed">
              <div class="c-results-item-head">
                <p class="label">掲載募集</p>
              </div>
              <div class="c-results-item-body">
                <h3 class="title">授業・教室・研修を実践例として<br>掲載してみませんか？</h3>
                <p class="description">皆様が行った授業・教室・研修の様子を、実践例として掲載することで、広く、くすり教育のノウハウを広めるためのご協力をお願いします。</p>
                <p class="description">ご協力いただける方は、お問い合わせよりご連絡くださいませ。</p>

                <p class="c-more-wrp">
                  <a class="c-button-more" href="https://www.rad-ar.or.jp/inquiry/" target="_blank" rel="noopener noreferrer">
                  { item.buttonText }
                  <svg xmlns="http://www.w3.org/2000/svg" width="9.669" height="8.878" viewBox="0 0 9.669 8.878">
                    <path d="M9843.642,4648.183l-4.15-3.94a.5.5,0,0,1-.155-.363.51.51,0,0,1,.155-.363l4.15-3.94a.5.5,0,1,1,.687.727l-3.355,3.186h7.651a.381.381,0,0,1,.381.381v.01a.381.381,0,0,1-.381.381h-7.662l3.366,3.2a.5.5,0,0,1-.687.724Z" transform="translate(-9839.336 -4639.44)" fill="#a9beb2"/>
                  </svg>

                  </a>

                </p>
              </div>
            </div>

            <!-- 通常のケースアイテム -->
            <a if={ !item.isFixed } href="{ item.slug }" class="c-results-item { item.publish === 'false' ? 'c-results-item--disabled' : '' }">

              <div class="c-results-item-head">
                <p class="label">実践例{ getItemIndex(item.slug) }</p>
                <p class="c-button-more">
                  詳しくみる
                  <svg xmlns="http://www.w3.org/2000/svg" width="9.669" height="8.878" viewBox="0 0 9.669 8.878">
                    <path d="M9843.642,4648.183l-4.15-3.94a.5.5,0,0,1-.155-.363.51.51,0,0,1,.155-.363l4.15-3.94a.5.5,0,1,1,.687.727l-3.355,3.186h7.651a.381.381,0,0,1,.381.381v.01a.381.381,0,0,1-.381.381h-7.662l3.366,3.2a.5.5,0,0,1-.687.724Z" transform="translate(-9839.336 -4639.44)" fill="#a9beb2"/>
                  </svg>
                </p>
              </div>
              <div class="c-results-item-body">
                <h3 class="title">{ item.name }</h3>
                <p class="description">
                { item.publish === 'false' ? 'ただいま準備中となります。' : (item.summary || getMainContent(item.repeatItem)) }
                </p>
                <h4 class="description__label">
                  <span>使用教材</span>
                </h4>
                <p class="description">
                  { item.publish === 'false' ? 'ただいま準備中となります。' : (getMaterialsList(item.materials)) }
                </p>
                <p class="description date">掲載日：
                { item.date }
                </p>
              </div>
            </a>
          </virtual>
        </div>
      </div>
    </div>
  </div>

  <script>
    export default {
      state: {
        cases: [],
        fixedJobBlock: {
          isFixed: true,
          title: "授業や研修を実践例とし掲載してみませんか？",
          description: "皆様が行った授業や研修の様子を、実践例として掲載することで、広く、くすり教育のノウハウを広めるためのご協力をお願いします。",
          description2: "ご協力いただける方は、お問い合わせよりご連絡くださいませ。",
          buttonText: "お問い合わせはこちらから"
        }
      },

      onMounted() {
        // JSONデータの読み込み
        fetch('../data/cases.json')
          .then(response => response.json())
          .then(data => {
            this.state.cases = data.items
            this.update()
          })
      },

      // displayItemsをcomputedプロパティとして定義
      get displayItems() {
        const result = []
        this.state.cases.forEach((item, index) => {
          if (index === 2) {
            result.push(this.state.fixedJobBlock)
          }
          result.push(item)
        })
        if (this.state.cases.length <= 2) {
          result.push(this.state.fixedJobBlock)
        }
        return result
      },

      getItemIndex(slug) {
        return this.state.cases.findIndex(item => item.slug === slug) + 1
      },

      getMainContent(repeatItem) {
        const mainContent = repeatItem.find(item => item.name === "授業の主な内容")
        return mainContent ? mainContent.txt : ''
      },

      getMaterialsList(materials) {
        return materials.map(m => m.name).join('、')
      }
    }
  </script>
</app-results-list>
