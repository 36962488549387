<app-top-posts>
  <section>
    <div class="c-top-post-list">
      <div each="{ item in state.items }" class="c-item-container">
        <a href="{ createItemLink(item) }" class="c-item-link">
          <div class="c-item-content">
            <div class="c-item-target-list">
              <div class="c-item-target" each="{ element in createCombinedList(item.targets, item.formats) }">
                { element }
              </div>
            </div>
            <div class="c-item-thumbnail">
              <img if="{ item.photo && item.photo.length > 0 }" src="{ item.photo[0] }" alt="{ item.name }" />
            </div>
            <div class="c-item-title">{ item.name }</div>
            <div class="c-item-description">{ item.description }</div>
          </div>
        </a>
      </div>
    </div>
  </section>

  <script>
    import 'whatwg-fetch'

    export default {
      state: {
        items: null
      },
      onMounted() {
        fetch('/data/data.json')
          .then(response => response.json())
          .then(data => {
            this.initData(data)
            this.update()
          })
      },
      initData(data) {
        this.state.items = data.items.slice(0, 4)
      },
      createItemLink(item) {
        const category = item.categories[0]
        const htmlFile = this.getCategoryHtmlFile(category)
        return `/items/${htmlFile}#${item.slug}`
      },
      getCategoryHtmlFile(category) {
        const categoryMap = {
          '動画': 'movie.html',
          'パワーポイント・リーフレット': 'document.html',
          'イラスト集': 'illust.html',
          '教材貸出': 'rental.html'
        }
        return categoryMap[category] || 'others.html'
      },
      createCombinedList(targets, formats) {
        const limitedTargets = targets.slice(0, 1)
        const limitedFormats = formats.slice(0, 2)

        if (this.checkTargetsAll(targets)) {
          return ['全対象者', ...limitedFormats]
        }

        return [...limitedTargets, ...limitedFormats]
      },
      checkTargetsAll(arr) {
        const targets = ['target1', 'target2', 'target3']
        return targets.every(target => arr.includes(target))
      }
    }
  </script>
</app-top-posts>
