<app-icon-link-2>
  <a href="{ props.dataLink }" class="c-icon-link-2" target="{ props.dataExternal ? '_blank' : false }"
    rel="{ props.dataExternal ? 'noopener' : false }">
    <div class="c-icon-link-2__icon" if="{ props.dataIcon }">
      <!--  <app-icon data-icon="{ props.dataIcon }" style="font-size: { props.dataFontSize }"></app-icon>  -->
      <img src="{ props.dataIcon }" alt="" class="c-icon-link__icon">
    </div>
    <div class="c-icon-link-2__label">{ props.dataLabel }</div>
    <div class="c-icon-link-2__external" if="{ props.dataExternal }">
      <app-icon data-icon="external" if="{ props.dataExternal }" class="c-icon-link-2__icon"></app-icon>
    </div>
  </a>
</app-icon-link-2>
