<app-icon-link>
  <a href="{ props.dataLink }" class="c-icon-link" target="{ getIsExternal( props.dataLink ) ? '_blank' : false}"
    rel="{ getIsExternal( props.dataLink ) ? 'noopener' : false}">
    <div class="o-flow-layout c-icon-link__layout">
      <div>
        <img src="{ props.dataImage }" alt="" class="c-icon-link__icon">
      </div>
      <div>
        <span class="c-icon-link__text">{ props.dataText }</span>
      </div>
      <div if="{ getIsExternal( props.dataLink ) }">
        <app-icon data-icon="external" class="c-icon-link__external"></app-icon>
      </div>
    </div>
  </a>
</app-icon-link>