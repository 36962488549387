<app-top-hero>
  <div class="c-top-hero" if="{ state.slidesData }">
    <div class="c-top-hero__slider js-slick-slider">
      <a each="{ slide in state.slidesData }" class="c-top-hero__slide" href="{ slide.link }"
        target="{ getIsExternal(slide.link) ? '_blank' : false}"
        rel="{ getIsExternal(slide.link) ? 'noopener' : false}">
        <img src="{ slide.image }" alt="{ slide.title }" width="1181">
      </a>
    </div>
  </div>

  <script>
    import $ from 'jquery'
    window.$ = window.jQuery = $
    import 'slick-carousel'

    export default function appTopHero() {
      return {
        state: {
          slidesData: null
        },
        onMounted() {
          fetch('/data/top-slides.json')
            .then(response => response.json())
            .then(data => {
              // console.log(data)
              this.state.slidesData = data
              this.update()
            })
        },
        onUpdated() {
          if (this.state.slidesData) {
            $('.js-slick-slider').slick({
              arrows: true,
              dots: true,
              autoplay: true,
              autoplaySpeed: 4000,
              pauseOnFocus: false,
              pauseOnHover: true,
              speed: 800,
              prevArrow: '<a class="slick-prev" href="#"><img src="../../img/arw_left.png"></a>',
              nextArrow: '<a class="slick-next" href="#"><img src="../../img/arw_right.png"></a>',
              //centerMode: true,
              //variableWidth: true,
            })
          }
        }
      }
    }
  </script>
</app-top-hero>
