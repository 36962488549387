<app-items-item>
  <div class="c-items-item">
    <!--  c-items-item__targets  -->
    <div class="c-item-target-list" if="{ !checkTargetsAll(props.dataData.targets) }" >
      <div class="c-item-target" each="{ target in props.dataData.targets }">{ target }</div>
    </div>
    <!--  c-items-item__title  -->
    <div class="c-items-item__title c-heading-6">
      <raw-html content="{ props.dataData.name }"></raw-html>
    </div>
    <!--  c-items-item-grid  -->
    <div class="c-items-item-grid">
      <app-carousel data-photos="{ props.dataData.photo }"></app-carousel>
      <div class="c-items-item__summary o-fit">
        <raw-html content="{ props.dataData.description }"></raw-html>
        <div class="c-items-item__slug">ID: { props.dataData.slug }</div>
      </div>
      <div class="c-items-item__buttons">
        <button class="c-button-3 c-items-item__button c-items-item__button--{ button.type }"
          onclick="{ clickButton.bind(this, button, props.dataData) }" each="{ button in props.dataData.links }">
          <div class="c-button-3__label">
            <raw-html content="{ button.name }"></raw-html>
          </div>
          <app-icon class="c-button-3__icon" data-icon="{ button.type }"></app-icon>
        </button>
      </div>
    </div>
  </div>

  <script>
    import $ from 'jquery'
    window.$ = window.jQuery = $

    export default {
      onMounted() {
        // console.log(this.props)
      },
      clickButton(){
        // console.log(arguments)
        if(arguments[0].required_mail){
          this.callModal(arguments)
        }
        else {
          const sendData = {
            'a1': '',
            'a2': arguments[0].name,
            'a3': arguments[0].url,
            'a4': `${arguments[1].slug}-${arguments[0].slug}`
          }

          const fileUrl = arguments[0].url

          $.post(location.origin + '/js/kyozai.cgi', sendData, function () {
            location.href = fileUrl
            location.hash = '#close-modal'
          }).fail(() => {
            // location.href = fileUrl
            // location.hash = '#close-modal'
            alert('通信中に問題が発生しました。\n恐れ入りますがしばらく立ってから再度お試しください。')
          })
        }
      },
      callModal() {
        this.props.dataCallModal(arguments[0])
      },
      dataCallModal(){
        this.state.modalData = arguments[0]
        this.update()
        location.href = "#modal"
      },
      checkTargetsAll(arr) {
        const targets = this.props.dataTargets.map(target => target.label)
        return this._.difference(targets, arr).length < 1
      }
    }
  </script>
</app-items-item>
