<app-items-search-modal>
  <div class="c-items-search-modal" id="modal">
    <a href="#close-modal" class="c-items-search-modal__closer"></a>
    <div class="c-items-search-modal__window" if="{ props.dataData }">
      <div class="o-stack-layout">
        <div>
          <div class="o-flow-layout o-flow-layout--middle o-flow-layout--no-wrap">
            <div class="o-width-fill">
              <input type="email" name="email" id="email" autocomplete="email" class="c-input c-input--fill"
                placeholder="メールアドレスをご入力ください" onblur="{ checkEmail }" onkeyup="{ checkEmail }">
            </div>
            <div class="o-width-fixed o-fit" if="{ state.emailValidated === true }">
              <p>
                <app-icon data-icon="check-circle" class="c-items-search-modal__icon c-items-search-modal__icon--check">
                </app-icon>
              </p>
            </div>
            <div class="o-width-fixed o-fit" if="{ !state.emailValidated && state.email.length > 0 }">
              <p>
                <app-icon data-icon="alert" class="c-items-search-modal__icon c-items-search-modal__icon--alert">
                </app-icon>
              </p>
            </div>
          </div>
          <p if="{ !state.emailValidated && state.email.length > 0 }" class="c-items-search-modal__error">
            メールアドレスの書式に問題があるようです。</p>
        </div>
        <div class="o-fit c-items-search-modal__content">
          <p>メールアドレスをご入力の上、<a href="/first" target="_blank">「はじめて利用する方へ」</a>に記載された注意事項をよく読み、同意いただいた上で以下のボタンをクリックしてください。</p>
        </div>
        <div>
          <p class=" o-fit c-item-search-modal__name c-items-search-modal__content">
            <raw-html content="{ props.dataData.item.name }"></raw-html>
          </p>
        </div>
        <div>
          <div class="o-stack-layout o-stack-layout--tight o-stack-layout--center">
            <div>
              <button type="submit"
                class="c-button-3 c-items-item__button c-items-item__button--fit c-items-item__button--{ props.dataData.button.type }"
                onclick="{ clickButton.bind(this, props.dataData.button) }"
                disabled="{ !state.emailValidated || state.email.length < 1 }">
                <div class="c-button-3__label">
                  <raw-html content="{ props.dataData.button.name }"></raw-html>
                </div>
                <app-icon class="c-button-3__icon" data-icon="{ props.dataData.button.type }"></app-icon>
              </button>
            </div>
            <div class="o-fit">
              <p class="c-item-search-modal__slug">ファイルID: { props.dataData.item.slug }-{ props.dataData.button.slug }</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <script>

    import $ from 'jquery'
    window.$ = window.jQuery = $

    export default {
      state: {
        emailValidated: 'initial',
        email: ''
      },
      onUpdated() {
        //console.log(this.props.dataData)
      },
      clickButton() {
        //console.log(arguments);

        const sendData = {
          'a1': this.state.email,
          'a2': arguments[0].name,
          'a3': arguments[0].url,
          'a4': `${this.props.dataData.item.slug}-${arguments[0].slug}`
        }

        const fileUrl = arguments[0].url

        $.post( location.origin + '/js/kyozai.cgi', sendData, function() {
          location.href = fileUrl
          location.hash = '#close-modal'
        }).fail(() => {
          // location.href = fileUrl
          // location.hash = '#close-modal'
          alert('通信中に問題が発生しました。\n恐れ入りますがしばらく立ってから再度お試しください。')
        })
      },
      checkEmailFormat(emailString) {
        return !!emailString.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)
      },
      checkEmail(e) {
        // console.log(e.target.value)
        this.state.email = e.target.value
        this.state.emailValidated = this.checkEmailFormat(this.state.email)
        this.update()
      },
    }
  </script>
</app-items-search-modal>
