<app-carousel>
  <div class="o-stack-layout">
    <div>
      <div class="c-carousel-wrapper">
        <div class="c-carousel" style="left: -{ state.current }00%;">
          <div each="{ photo in props.dataPhotos }" class="c-items-item__photo" style="background-image: url({ photo })"
            onclick="{ changeCurrentNext }"></div>
        </div>
      </div>
    </div>
    <div>
      <div class="c-carousel-dots">
        <button each="{ (dot, index) in props.dataPhotos }" class="c-carousel-dots__dot"
          aria-selected="{ state.current == index ? 'true' : 'false' }"
          onclick="{ changeCurrent.bind(this, index) }"></button>
      </div>
    </div>
  </div>

  <script>
    export default {
      state: {
        current: 0
      },
      changeCurrent(index) {
        this.state.current = index
        this.update()
      },
      changeCurrentNext() {
        this.state.current++
        if (this.props.dataPhotos.length <= this.state.current) {
          this.state.current = 0
        }
        this.update()
      }
    }
  </script>
</app-carousel>
