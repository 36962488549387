<app-items-search-result>
  <div class="c-items-search-result">
    <p class="c-item-search-count">
      { props.dataItems.length } <small class="c-item-search-count__total">/ { props.dataTotal } 件</small>
    </p>
    <div class="c-items-search-result-inner" if="{ props.dataItems.length > 0 }">
      <div each="{ item in props.dataItems }">
        <app-items-item data-targets="{ props.dataTargets }" data-data="{ item }" data-call-modal="{ dataCallModal }"></app-items-item>
      </div>
    </div>
  </div>
  <app-items-search-modal data-data="{ state.modalData }"></app-items-search-modal>

  <script>
    export default {
      state: {
        modalData: null
      },
      dataCallModal(){
        this.state.modalData = arguments[0]
        this.update()
        location.href = "#modal"
      }
    }
  </script>
</app-items-search-result>
